import React from "react"
import DigitalPage from "../components/solution/digitalHealth/digital"

const DigitalHealth = () => {
  return (
    <>
      <DigitalPage/>
    </>
  )
}

export default DigitalHealth
