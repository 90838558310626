import React from "react"
import { withTranslation } from "react-i18next"
import img1 from "../../../../assets/img/media/solution/4PC/pc1.png"

import "./pcs.scss"

const PcsPage = props => {
  const { t } = props
  return (
    <>
      <div className="coverPcs container">
        <h1>{t("src.pages.digitalHealthPage.pcs.title")} </h1>
        <p>{t("src.pages.digitalHealthPage.pcs.content")} </p>
        <img alt="img" src={img1} />
      </div>
      <div className="coverPcs largeLED  ">
        <div className="container">
          <h1>{t("src.pages.digitalHealthPage.pcs.ledTitle")} </h1>
          <p>{t("src.pages.digitalHealthPage.pcs.ledContent")}</p>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(PcsPage)
