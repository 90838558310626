import React from "react"
import { withTranslation } from "react-i18next"
import img1 from "../../../../assets/img/media/solution/Accurately/accurately.png"
import left from "../../../../assets/img/media/solution/Accurately/group-left.png"
import right from "../../../../assets/img/media/solution/Accurately/right.png"

import "./accurately.scss"

const AccuratelyPage = props => {
  const { t } = props
  return (
    <>
      <div className="coverAccurately container">
        <h1>{t("src.pages.digitalHealthPage.measure.title")}</h1>
        <p>{t("src.pages.digitalHealthPage.measure.descriptions")}</p>
        <img alt="img" src={img1} />
      </div>
      <div className="coverEnergy">
        <div className="container coverItem">
          <h1>{t("src.pages.digitalHealthPage.energySaving.title")}</h1>
          <div className="cover2Item">
            <div className="item">
              <img alt="img" src={left}></img>
              <p>{t("src.pages.digitalHealthPage.energySaving.content1")}</p>
            </div>
            <div className="item">
              <img alt="img" src={right}></img>
              <p>{t("src.pages.digitalHealthPage.energySaving.content2")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(AccuratelyPage)
