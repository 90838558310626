import React, { Component } from "react"
import Solution from "../solutionTitle/solutionTitle"
import Layout from '../../layout/layout.higherComponent'
import Head from '../../head'
import AccuratelyPage from './accurately/accurately'
import PcsPage from './pcs/pcs'

class DigitalPage extends Component {
  render() {
    return (
      <>
        <Layout displayHeader='true'>
          <Head title="Solutions" />
          <Solution />
          <AccuratelyPage />
          <PcsPage />
        </Layout>
      </>
    )
  }
}

export default DigitalPage
