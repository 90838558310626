import React from "react"
import { withTranslation } from "react-i18next"
import logo from "../../../assets/img/media/solution/title/Logo.png"
import can from "../../../assets/img/media/solution/title/bg2.png"
import mini1 from "../../../assets/img/media/solution/360/icon1.svg"
import mini2 from "../../../assets/img/media/solution/360/icon2.svg"
import mini3 from "../../../assets/img/media/solution/360/icon3.svg"
import mini4 from "../../../assets/img/media/solution/360/icon4.svg"

import large1 from "../../../assets/img/media/solution/360/icon1-1.svg"
import large2 from "../../../assets/img/media/solution/360/icon2-1.svg"
import large3 from "../../../assets/img/media/solution/360/icon3-1.svg"
import large4 from "../../../assets/img/media/solution/360/icon4-1.svg"

import "./solutionTitle.scss"

const Solution = props => {
  const { t } = props
  return (
    <div className="coverSolutionTitle">
      <div className="bigBG">
        <div className="coverBg1">
          <div className="coverH1">
            <h1>{t("src.pages.digitalHealthPage.title")}</h1>
          </div>
        </div>

        <div className="coverP">
          <p>{t("src.pages.digitalHealthPage.descriptions")}</p>
        </div>
      </div>

      <div className="coverBottom">
        <div className="left">
          <img alt="img" className="can" src={can}></img>
        </div>
        <div className="right">
          <img alt="img" className="logo" src={logo}></img>
          <div className="divCoverDigi">
            <h2>{t("src.pages.digitalHealthPage.digitalThermo.title")}</h2>
          </div>
          <h5>{t("src.pages.digitalHealthPage.digitalThermo.content")}</h5>
          <div className="coverThermo">
            <h6>{t("src.pages.digitalHealthPage.digitalThermo.descriptions")}</h6>
          </div>
        </div>
      </div>
      <div className="coverThermo__mobile">
            <h6>{t("src.pages.digitalHealthPage.digitalThermo.descriptions")}</h6>
          </div>
      <div className="cover4Icon small-screen">
        <div className="text-cover">
          <img alt="img" className="360Icon" src={mini1}></img>
          <h5>{t("src.pages.digitalHealthPage.digitalThermo.sensor")}</h5>
        </div>
        <div className="text-cover">
          <img
            alt="img"
            style={{ marginLeft: "6px" }}
            className="360Icon"
            src={mini2}
          ></img>
          <h5 style={{ marginLeft: "25px" }}>
            {t("src.pages.digitalHealthPage.digitalThermo.microSD")}
          </h5>
        </div>
        <div className="text-cover">
          <img
            alt="img"
            style={{ marginLeft: "12px" }}
            className="360Icon"
            src={mini3}
          ></img>
          <h5> {t("src.pages.digitalHealthPage.digitalThermo.energy")}</h5>
        </div>
        <div className="text-cover">
          <img alt="img" className="360Icon" src={mini4}></img>
          <h5> {t("src.pages.digitalHealthPage.digitalThermo.indoor")}</h5>
        </div>
      </div>

      {/* large screen  */}
      <div className="cover4Icon2 large-screen">
        <div className="text-cover">
          <img alt="img" className="360Icon" src={large1}></img>
          <h5>{t("src.pages.digitalHealthPage.digitalThermo.sensor")}</h5>
        </div>
        <div className="text-cover">
          <img alt="img" className="360Icon" src={large2}></img>
          <h5> {t("src.pages.digitalHealthPage.digitalThermo.microSD")}</h5>
        </div>
        <div className="text-cover">
          <img alt="img" className="360Icon" src={large3}></img>
          <h5> {t("src.pages.digitalHealthPage.digitalThermo.energy")}</h5>
        </div>
        <div className="text-cover">
          <img alt="img" className="360Icon" src={large4}></img>
          <h5> {t("src.pages.digitalHealthPage.digitalThermo.indoor")}</h5>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Solution)
